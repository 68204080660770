<template>
   <ReturnsReturnLines :current-id="currentId"/>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import ReturnsReturnLines from '@/components/ReturnsReturnLines';

export default defineComponent({
  components: {
    ReturnsReturnLines,
  },
  setup() {
    const route = useRoute();

    const currentId = computed(() => {
      return route.params.uid
    });

    return {
      currentId
    }

  }
});
</script>